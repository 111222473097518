import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

//------------------------------------------------------------------------------

function BackgroundImage(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { children } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return <div className={props.className}>{children}</div>
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

BackgroundImage.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node,
}

BackgroundImage.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledBackgroundImage = styled(BackgroundImage)`
  & {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-height: ${props => props.height || "auto"};
    min-width: 100%;

    background-image: url(${props => props.image});
    background-size: cover;
    background-repeat: no-repeat;
  }
`
// ----------------------------------------------------------------------------

export default StyledBackgroundImage
