import React from "react"
import styled from "styled-components"

import Layout from "../components/_shared/Layout"
import SEO from "../components/_shared/SEO"
import Card from "../components/_shared/Card"
import BackgroundImage from "../components/_shared/BackgroundImage"

// ----------------------------------------------------------------------------
const NotFoundPage = props => (
  <Layout className={props.className}>
    <SEO title="404: Not found" />

    <BackgroundImage
      className="background-container"
      image={require("../images/_shared/chuttersnap.png")}
      width="100%"
    >
      <Card className="card">
        <div className="card-content">
          <h2>
            Ops!
            <br />
            Questa pagina
            <br />
            non esiste.
          </h2>

          <h6>ERRORE 404</h6>
        </div>
      </Card>
    </BackgroundImage>
  </Layout>
)

// ----------------------------------------------------------------------------

const StyledNotFoundPage = styled(NotFoundPage)`
  & {
    > main {
      display: flex;
      flex-direction: column;
      align-items: center;

      .background-container {
        flex: 1;
        width: 100vw;
      }

      .card-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        > h2 {
          text-align: center;
        }

        > h6 {
          margin-top: 13.4px;

          font-size: 14px;
          line-height: 18px;
          font-weight: 600;
          color: ${props => props.theme.yaleBlue};
        }
      }
    }

    /* MOBILE */

    @media (max-width: ${props => props.theme.mediaQueries.mobile}) {
      > main {
        min-height: calc(100vh - 50px);

        .card {
          box-sizing: border-box;

          width: 90vw;

          .card-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            > h2 {
              text-align: center;
            }

            .info-container {
              display: flex;
              flex-direction: column;

              .column {
                text-align: center;
              }

              .second-column {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
`

export default StyledNotFoundPage
