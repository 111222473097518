import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

//------------------------------------------------------------------------------

function Card(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { children } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return <div className={props.className}>{children}</div>
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

Card.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node,
}

Card.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledCard = styled(Card)`
  & {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 50px;
    border-radius: 4px;
    background-color: ${props => props.theme.white};
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    /* MOBILE */

    @media (max-width: ${props => props.theme.mediaQueries.mobile}) {
      padding: 45px 21px;
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledCard
